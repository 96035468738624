import React from "react";
import "./SettingDashboard.scss";
import { useState } from "react";

export default function SettingDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    "عام",
    "الحساب",
    "مستلم الدفعات",
    "التبيهات ",
    "الامان",
    "بريد الدعم",
  ];
  const tabContent = [
    <div key="content1" className="content-taps-setting">
      <label htmlFor="language" className="name-input">اللغة</label>
      <select
        class="form-select form-select-lg mb-3 input-change-style"
        aria-label="Large select example"
      >
        <option selected>عربي</option>
        <option value="1">english</option>
      </select>
      <label htmlFor="theme" className="name-input">المظهر</label>
      <select
        class="form-select form-select-lg mb-3 input-change-style"
        aria-label="Large select example"
      >
        <option value="1">مضئ</option>
        <option selected>ليلي</option>
      </select>
    </div>,
    <div key="content2" className="content-taps-setting">
      <div class="account-container">
  <div class="account-header">
    الحساب
  </div>

  <div class="account-card">
    <h3>قم بتأكيد بريدك الإلكتروني</h3>
    <p>
      انتقل إلى البريد الخاص بك وانقر فوق ارتباط التنشيط. إذا لم تتلق أي رسائل منا،
      فانقر فوق الزر المجاور لـ
    </p>
    <button class="action-btn">أرسل رابط التفعيل</button>
  </div>

  <div class="delete-account-container">
    <div class="delete-header">
      حذف الحساب
    </div>
    <button class="delete-btn">حذف الحساب</button>
  </div>
</div>

    </div>,
    <div key="content4" className="content-taps-setting">
     
     <div class="form-container">
  <form class="address-form">
    <div class="form-group">
      <label for="firstName">الاسم الاول <span class="required">*</span></label>
      <input type="text" id="street" placeholder="الاسم الاول" />
    </div>
    <div class="form-group">
      <label for="lastName">الاسم الاخير <span class="required">*</span></label>
      <input type="text" id="street" placeholder="الاسم الاخير" />
    </div>
    <div class="form-group">
      <label for="street">شارع <span class="required">*</span></label>
      <input type="text" id="street" placeholder="شارع" />
    </div>

    <div class="form-group">
      <label for="house-number">رقم الدار</label>
      <input type="text" id="house-number" placeholder="رقم الدار" />
    </div>

    <div class="form-group">
      <label for="city">مدينة <span class="required">*</span></label>
      <input type="text" id="city" placeholder="مدينة" />
    </div>

    <div class="form-group">
      <label for="postal-code">الرمز البريدي</label>
      <input type="text" id="postal-code" placeholder="الرمز البريدي" />
    </div>

    <div class="form-group">
      <label for="region">الولاية أو المنطقة</label>
      <input type="text" id="region" placeholder="الولاية أو المنطقة" />
    </div>

    <div class="form-group">
      <label for="country">دولة <span class="required">*</span></label>
      <select id="country">
  <option value="">اختر دولة</option>
  <option value="Afghanistan">أفغانستان</option>
  <option value="Albania">ألبانيا</option>
  <option value="Algeria">الجزائر</option>
  <option value="Andorra">أندورا</option>
  <option value="Angola">أنغولا</option>
  <option value="Argentina">الأرجنتين</option>
  <option value="Armenia">أرمينيا</option>
  <option value="Australia">أستراليا</option>
  <option value="Austria">النمسا</option>
  <option value="Azerbaijan">أذربيجان</option>
  <option value="Bahrain">البحرين</option>
  <option value="Bangladesh">بنغلاديش</option>
  <option value="Belarus">بيلاروسيا</option>
  <option value="Belgium">بلجيكا</option>
  <option value="Bolivia">بوليفيا</option>
  <option value="Bosnia and Herzegovina">البوسنة والهرسك</option>
  <option value="Brazil">البرازيل</option>
  <option value="Bulgaria">بلغاريا</option>
  <option value="Canada">كندا</option>
  <option value="Chile">تشيلي</option>
  <option value="China">الصين</option>
  <option value="Colombia">كولومبيا</option>
  <option value="Croatia">كرواتيا</option>
  <option value="Cuba">كوبا</option>
  <option value="Cyprus">قبرص</option>
  <option value="Czech Republic">التشيك</option>
  <option value="Denmark">الدنمارك</option>
  <option value="Egypt">مصر</option>
  <option value="Finland">فنلندا</option>
  <option value="France">فرنسا</option>
  <option value="Germany">ألمانيا</option>
  <option value="Greece">اليونان</option>
  <option value="India">الهند</option>
  <option value="Indonesia">إندونيسيا</option>
  <option value="Iran">إيران</option>
  <option value="Iraq">العراق</option>
  <option value="Ireland">أيرلندا</option>
  <option value="Italy">إيطاليا</option>
  <option value="Japan">اليابان</option>
  <option value="Jordan">الأردن</option>
  <option value="Kuwait">الكويت</option>
  <option value="Lebanon">لبنان</option>
  <option value="Libya">ليبيا</option>
  <option value="Morocco">المغرب</option>
  <option value="Netherlands">هولندا</option>
  <option value="New Zealand">نيوزيلندا</option>
  <option value="Oman">عُمان</option>
  <option value="Pakistan">باكستان</option>
  <option value="Palestine">فلسطين</option>
  <option value="Qatar">قطر</option>
  <option value="Russia">روسيا</option>
  <option value="Saudi Arabia">السعودية</option>
  <option value="South Africa">جنوب أفريقيا</option>
  <option value="Spain">إسبانيا</option>
  <option value="Sudan">السودان</option>
  <option value="Sweden">السويد</option>
  <option value="Switzerland">سويسرا</option>
  <option value="Syria">سوريا</option>
  <option value="Tunisia">تونس</option>
  <option value="Turkey">تركيا</option>
  <option value="United Arab Emirates">الإمارات</option>
  <option value="United Kingdom">المملكة المتحدة</option>
  <option value="United States">الولايات المتحدة</option>
  <option value="Yemen">اليمن</option>
</select>

    </div>

    <div class="form-group save-button">
      <button type="submit">يحفظ</button>
    </div>
  </form>
</div>


    </div>,
    <div key="content5" className="content-taps-setting">
        <label htmlFor="language" className="name-input">الاشعارات</label>
      <select
        class="form-select form-select-lg mb-3 input-change-style"
        aria-label="Large select example"
      >
        <option selected>مفعل</option>
        <option value="1">غير مفعل</option>
      </select>
    </div>,
    <div key="content6" className="content-taps-setting">
     <div className="contaier-notifiations">
        <h1 className="title-note">نشاطك الأمني الأخير من 28 يومًا الماضية.</h1>
      </div>
    </div>,
    <div key="content6" className="content-taps-setting">
      <div className="contaier-notifiations">
        <h1 className="title-note">صندوق بريد الدعم الخاص بك</h1>
        <p className="desc-note">إذا أبلغت عن مشكلة، فسوف يظهر الجواب هنا</p>
      </div>
    </div>,
  ];

  const [isLangOpen, setLangOpen] = useState(false);
  const [isThemeOpen, setThemeOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("العربية");
  const [selectedTheme, setSelectedTheme] = useState("مظهر داكن");

  const languages = ["العربية", "English"];
  const themes = ["مظهر داكن", "مظهر فاتح"];

  const handleLangClick = (lang) => {
    setSelectedLanguage(lang);
    setLangOpen(false);
  };

  const handleThemeClick = (theme) => {
    setSelectedTheme(theme);
    setThemeOpen(false);
  };

  return (
    <div id="setting-page">
      <div className="vertical-tabs">
        <div className="container-content-taps-setting">
          {tabContent[activeTab]}
        </div>

        <div className="tabs">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`tab-btn ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
