import React, { useContext, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom"; // Import Navigate for redirection
import Swal from "sweetalert2"; // Import SweetAlert2
import "./Login.scss";
import imageLogo from "../../assets/download.png";
import imageGoogle from "../../assets/google.png";
import { User } from "../../Context/AuthContext/AuthContext";

export default function Login() {
  const userNaw = useContext(User)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error handling
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axios.post("https://bor-admin.elwakeil.com/public/api/login", {
        email: formData.email,
        password: formData.password,
      });
const token = response.data.data.token
const userDetails = response.data.data.user
      // On success, store token or navigate
      userNaw.setAuth({token , userDetails })
      // console.log("Login successful:", response.data.data);
      localStorage.setItem("token", response.data.token); // Store token
      setIsLoggedIn(true); // Set login status to true

    } catch (err) {
      // Handle errors and show SweetAlert for invalid credentials
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response?.data?.message || "Invalid credentials. Please try again.",
      });
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    // If logged in, redirect to the Admin page
    return <Navigate to="/admin" />; // Redirect to Admin Component
  }

  return (
    <>
      <form id="main-login" onSubmit={handleSubmit}>
        <img src={imageLogo} alt="" className="image-logo" />
        <h1 className="heading">Start earning online</h1>
        <p className="description">Welcome to Paidwork</p>

        <input
          type="email"
          name="email"
          placeholder="Email"
          className="inputs"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          className="inputs"
          value={formData.password}
          onChange={handleInputChange}
        />
        <button className="style-button" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Continue"}
        </button>
        {error && <p className="error">{error}</p>}
        <p>OR</p>
        <div className="container-other-way">
          <img src={imageGoogle} alt="" className="images" />
          <p className="title">google</p>
        </div>
      </form>
    </>
  );
}
