import React, { useContext, useEffect, useState } from "react";
import "./DashboardUser.scss";
import { FiHome } from "react-icons/fi";
import { MdOutlineAttachMoney } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import logoImage from "../../assets/logo.png";
import HomeDashboard from "./DashboardComponent/HomeDashboard/HomeDashboard";
import ProfitDashboard from "./DashboardComponent/profitDashboard/ProfitDashboard";
import SettingDashboard from "./DashboardComponent/settingDashboard/SettingDashboard";
import ProgramDashboard from "./DashboardComponent/programDashboard/ProgramDashboard";
import { User } from "../../Context/AuthContext/AuthContext";
export default function DashboardUser() {
 const user = useContext(User)
  const userDetails = user.auth.userDetails ;
  
    const [page,setPage] = useState('home');
    const [component,setComponent] = useState('<HomeDashboard/>');
    function changeComp() {
        if (page ===  'home') {
            setComponent(<HomeDashboard/>)
        } else if(page ===  'profit') {
            setComponent(<ProfitDashboard/>)
        } else if(page ===  'program') {
            setComponent(<ProgramDashboard/>)
        } else if(page ===  'setting') {
            setComponent(<SettingDashboard/>)
        }
    }
    function changOption(arg){
        setPage(arg)
    }
    useEffect(()=>{
        changeComp()
    },[page])
  return (
    <div id="Dashboard-page">
      <div className="header-dashboard">
        <h1 className="user-name">{userDetails.name}</h1>
        <img src={logoImage} alt="" className="logo-image" />
      </div>
      <div className="content-dashboard">
      {component}
      </div>
      <div className="sid-bar-dashboard">
        <div className="container-option" onClick={()=>changOption('home')}>
          <FiHome className="icon-option" />
          <p className="title-option">الصفحة الرئيسية</p>
        </div>
        <div className="container-option" onClick={()=>changOption('profit')}>
          <MdOutlineAttachMoney className="icon-option" />
          <p className="title-option">يكسب</p>
        </div>
        <div className="container-option" onClick={()=>changOption('program')}>
          <HiUsers className="icon-option" />
          <p className="title-option">برنامج الاحالة</p>
        </div>
        <div className="container-option" onClick={()=>changOption('setting')}>
          <IoSettingsSharp className="icon-option" />
          <p className="title-option">الاعدادات</p>
        </div>
        <div className="container-option" onClick={()=>changOption('logOut')}>
          <IoIosLogOut className="icon-option" />
          <p className="title-option">تسجيل الخروج</p>
        </div>
      </div>
    </div>
  );
}
