import React from 'react'
import './ProgramDashboard.scss'
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaSquareXTwitter } from "react-icons/fa6";
import { PiPaperPlaneTiltFill } from "react-icons/pi";

export default function ProgramDashboard() {
  return (
    <div id='program-page'>
        <div class="referral-section">
  <div class="referral-header">
    <h2>احصل على 10$ لكل شخص</h2>
  </div>
  <p class="referral-description">
    ادع صديقًا أو شخصًا من الإنترنت لكسب المال على Paidwork. إذا قدم الشخص أول دفعة، فسيحصل كلاكما على 10$ مجانًا. 
  </p>
  <div class="referral-link-section">
    <span>رابط الإحالة الخاص بك</span>
    <div class="link-box">
      <input type="text" value="https://www.paidwork.com/?r=elwakeelali05" readonly />
      <button class="copy-button">نسخ الوصلة</button>
    </div>
  </div>
  <div className="container-sm-btn">

  <div class="social-icons">
    <FaFacebook className='icon-sm face'/>
    <IoLogoWhatsapp className='icon-sm whatsapp'/>
    <FaSquareXTwitter className='icon-sm twitter'/>
    <PiPaperPlaneTiltFill className='icon-sm tel'/>

  </div>
  <button className='btn-change-link'> تغيير الارتباط</button>
  </div>
</div>
        <div class="referral-section">
<h1 className='title-section'>قم بدعوة شخص ما للظهور هنا</h1>
</div>

    </div>
  )
}
