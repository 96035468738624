import "./PayMethod.scss"
export default function PayMethod(props) {
return(
    <>
     <div className="container-heading">
          <h1 className="heading"> طرق الدفع عبر موقعنا </h1>
          <p className="description">
            احصل على أقصى استفادة من مكافآتك عبر الإنترنت معنا. استفد من
            المدفوعات السريعة، وأعلى معدلات CPM، وتجربة مستخدم لا مثيل لها. نحن
            نتمتع بثقة لأكثر من 5 سنوات، ونعتبر بوابتك إلى تحقيق أرباح رقمية
            معززة. انضم الآن واستمتع بتجربة تحقيق الدخل من عناوين URL بشكل لم
            يسبق له مثيل.
          </p>
        </div>
    <div id="container-images-pay-method" className="container">
{props.paymentMethods.map((method) => (
    <img src={`${props.baseUrl}${method.image}`} alt="" className="image-pay-method" />
))}
</div>

    </>
)
}