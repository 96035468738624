import "./StartProfit.scss";
import { IoIosArrowForward } from "react-icons/io";
import image from "../../assets/image.png";
export default function StartProfit() {
  return (
    <>
      <div id="container-Start-Profit" className="container">
        <div className="container-content">
          <p className="heading-text">أطلق العنان لقوة الروابط النقدية!</p>
          <p className="description">
            ادخل إلى عالم حيث يعزز كل رابط تشاركه أرباحك. قم بالتبسيط والمشاركة
            وشاهد المكافآت تتدفق إليك. هل أنت مستعد لتحويل رحلتك عبر الإنترنت؟
          </p>
          <button className="btn btn-primary style-gitstart">ابدأ في الكسب الآن!</button>
        </div>
        <div className="container-image">
          <img src={image} alt="" className="image"/>
        </div>
      </div>
    </>
  );
}