import React, { useEffect, useState } from 'react'
import './ProofPayment.scss'
import image from '../../assets/pexels-alena-shekhovtcova-6075014.jpg'
import Table from '../../components/tables/Table'
import axios from 'axios'
export default function ProofPayment() {
  const [paymentProofs , setPaymentProofs] = useState([])
  const fetchDataEarningExperiences = async () => {
    try {
      const response = await axios.get("https://bor-admin.elwakeil.com/public/api/payment_rates");
      setPaymentProofs(response.data.data);
      console.log(response.data.data);
      
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(()=>{
    fetchDataEarningExperiences()
  },[])
  return (
    <>
    <div id='main-proof-payment-page' style={{ backgroundImage: `url(${image})` }}>
      <div className='filter'>

      <h1 className='title-page'>Proof of Payment</h1>
      </div>
    </div>
      <Table paymentProofs={paymentProofs}/>
      </>
  )
}
