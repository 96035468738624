import { useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import ProofPayment from "./pages/proofPayment/ProofPayment";
import Header from "./components/header/Header";
import { Route, Routes } from "react-router-dom";
import PayoutRates from "./pages/payoutRates/PayoutRates";
import Login from "./pages/login/Login";
import SignIn from "./pages/signIn/SignIn";
import DashboardUser from "./pages/dashboardUser/DashboardUser";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import UserProvider from "./Context/AuthContext/AuthContext";

function App() {
  const location = useLocation();

  return (
    <UserProvider>
      <div className="App">
        {location.pathname !== "/admin" && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payoutrates" element={<PayoutRates />} />
          <Route path="/proofpayment" element={<ProofPayment />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signin" element={<SignIn />} />
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route path="/admin" element={<DashboardUser />} />
          </Route>
        </Routes>
      </div>
    </UserProvider>
  );
}

export default App;
