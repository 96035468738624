import React from 'react'
import './Table.scss'
export default function Table(props) {
  return (
    <div id='main-table-section'>
        
        <table class="table table-striped table-hover style-table">
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Username</th>
      <th scope="col">Status</th>
      <th scope="col">Amount</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    {props.paymentProofs.map((item)=> (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.username}</td>
      <td>{item.status}</td>
      <td>${item.amount}</td>
      <td>{item.created_at}</td>
    </tr>
    ))}
  </tbody>
</table>

    </div>
  )
}
