import axios from "axios"
import Accordion from "../../components/accordion/Accordion"
import Counter from "../../components/counter/Counter"
import Footer from "../../components/foooter/Footer"
import Header from "../../components/header/Header"
import PayMethod from "../../components/payMethod/PayMethod"
import ProfitDetails from "../../components/ProfitDetails/ProfitDetails"
import StartProfit from "../../components/startProfit/StartProfit"
import ViewSection from "../../components/viewsSection/ViewSection"
import "./Home.scss"
import { useEffect, useState } from "react"
export default function Home(){

  const [counters , setCounters] = useState([])
  const [questions , setQuestions] = useState([])
  const [paymentMethods , setPaymentMethods] = useState([])
  const [earningExperiences , setEarningExperiences] = useState([])

  const fetchDataCounters = async () => {
    try {
      const response = await axios.get("https://bor-admin.elwakeil.com/public/api/numbers");
      setCounters(response.data.data);
      
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataQuestions = async () => {
    try {
      const response = await axios.get("https://bor-admin.elwakeil.com/public/api/questions");
      setQuestions(response.data.data);      
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataPaymentMethods = async () => {
    try {
      const response = await axios.get("https://bor-admin.elwakeil.com/public/api/payment-methods");
      setPaymentMethods(response.data.data);
      
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataEarningExperiences = async () => {
    try {
      const response = await axios.get("https://bor-admin.elwakeil.com/public/api/earning-experiences");
      setEarningExperiences(response.data.data);
      console.log(response.data.data);
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataCounters();
    fetchDataQuestions();
    fetchDataPaymentMethods();
    fetchDataEarningExperiences();
  }, []);
    const data = [
        {
          title: 'ما هي هذه الخدمة؟',
          content: 'نحن نقدم خدمة اختصار الروابط التي لا تجعل الروابط الخاصة بك أنظف فحسب، بل تتيح لك أيضًا كسب المال في كل مرة ينقر عليها شخص ما.'
        },
        {
          title: 'كيف أبدأ في الكسب؟',
          content: 'ما عليك سوى التسجيل واختصار عنوان URL المطلوب من خلال منصتنا ومشاركة الرابط الجديد. ستربح المال مع كل نقرة!'
        },
        {
          title: 'كيف يعمل نظام الدفع؟',
          content: 'بمجرد وصولك إلى الحد الأدنى للدفع وهو 2 دولار، يمكنك طلب السحب. تتم معالجة المدفوعات خلال 1-7 أيام.'
        },
        {
          title: 'ماذا يعني CPM؟ ',
          content: 'CPM تعني "التكلفة لكل ألف نقرة". نحن نقدم أسعارًا مختلفة للتكلفة لكل ألف نقرة تصل إلى 8 دولارات، حسب البلد ونوعية الزيارات.'
        },
        {
          title: 'كيف أقوم بتعطيل رموز التحقق؟ ',
          content: 'يمكن للمستخدمين الذين يستوفون شروطًا معينة تعطيل رموز التحقق للحصول على تجربة مستخدم أكثر سلاسة. يمكن العثور على المعايير التفصيلية في إعدادات لوحة المعلومات الخاصة بك.'
        },
        {
          title: ' كيف يمكنني الحصول على الدعم؟',
          content: 'فريق الدعم المخصص لدينا جاهز دائمًا لمساعدتك. ما عليك سوى التواصل معنا عبر نموذج الاتصال الخاص بنا، وسنرد عليك على الفور.'
        },
        {
          title: 'ما هو برنامج الإحالة؟ ',
          content: 'من خلال دعوة مستخدمين جدد إلى منصتنا، يمكنك كسب 10% -25% إضافية من أرباحهم، دون أي خصم من جانبهم.'
        },
        {
          title: ' هل بياناتي آمنة؟',
          content: 'بالتأكيد! نحن نعطي الأولوية لأمن مستخدمينا، ونضمن حماية جميع البيانات وتأمينها.'
        },
        {
          title: 'كيف أستفيد من برنامج المكافآت؟ ',
          content: 'تم تصميم برنامج المكافآت الخاص بنا للمستخدمين المخلصين. حافظ على نشاطك وستتمكن من فتح مكافآت وميزات إضافية.'
        },
        {
          title: ' هل يمكنني أن أثق بهذه الخدمة؟',
          content: 'نعم، نحن نعمل في هذا المجال منذ أكثر من 5 سنوات، ونكافئ مستخدمينا باستمرار ونضمن رضاهم. نفخر بتقديم منصة جديرة بالثقة وموثوقة لاختصار عناوين URL وتحقيق الربح منها.'
        },
      ];
return(
    <>
    {/* <Header/> */}
    <ViewSection/>
    <Counter counters={counters}/>
    <ProfitDetails earningExperiences={earningExperiences}/>
    <Accordion questions={questions}/>
    <PayMethod paymentMethods={paymentMethods} baseUrl={"https://bor-admin.elwakeil.com/public/"}/>
    <StartProfit/>
    {/* <Footer/> */}
    </>
)
}