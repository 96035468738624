import "./ViewSection.scss";
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import './styles.css';
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";
import { IoIosArrowForward } from "react-icons/io";
import image from "../../assets/image.png";

export default function ViewSection() {
  return (
    <>
      <div id="container-view-section" className="container">
        <div className="container-content">
          <button type="button" class="btn btn-light my-style-btn-new">
            {/* <div className="container-new">جديد</div> */}
            يمكنك كسب ٥٠ دولار يوميا بمعدل شهري حاولي ١٬٥٠٠ دولار شهريا
            <IoIosArrowForward className="arrow"/>
          </button>
          <p className="heading-text">
            تصفح الروابط المختصره واكسب 
            <span>  الأموال </span>
          </p>
          <h1 className="title-view">
          
            استثمر وقتك في تصفح المواقع
          </h1>
          <div className="container-colors">
            <Swiper
              direction={"vertical"}
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{
                delay: 2000, // Delay between slides in milliseconds (3 seconds here)
                disableOnInteraction: false, // Continue autoplay after user interactions
              }}
              modules={[Mousewheel, Pagination, Autoplay]}
              className="mySwiper myStyle-swiper"
            >
              <SwiperSlide className="item item0"> اشترك معنا</SwiperSlide>
              <SwiperSlide className="item item1">
                تصفح الروابط الاعلانيه
              </SwiperSlide>
              <SwiperSlide className="item item2">اسحب أموالك</SwiperSlide>
              <SwiperSlide className="item item3"> اكسب معنا</SwiperSlide>
            </Swiper>
          </div>
          <p className="description">
            اكسب معنا وكن من الأوائل وكن من بين الأوائل الذين يستفيدون من فرص
            الربح الفريدة التي نقدمها. استغل قدراتك، و تصفح الروابط 
          </p>
          <button className="btn btn-primary style-gitstart">البدأ</button>
        </div>
        <div className="container-image">
          <img src={image} alt="" width="70%" />
          {/* <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
  <circle cx="50" cy="50" r="40" fill="#FFD700">
    <animate attributeName="r" from="30" to="40" dur="1s" repeatCount="indefinite" />
  </circle>
  <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" fill="#FFFFFF">$</text>
</svg> */}

        </div>
      </div>
    </>
  );
}
