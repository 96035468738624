import "./ProfitDetails.scss";
import image from "../../assets/image.png";
export default function ProfitDetails(props) {
  return (
    <>
      <div id="main-page-profit-details" className="container">
        <div className="container-heading">
          <h1 className="heading">ارتقِ بتجربة أرباحك</h1>
          <p className="description">
            احصل على أقصى استفادة من مكافآتك عبر الإنترنت معنا. استفد من
            المدفوعات السريعة، وأعلى معدلات CPM، وتجربة مستخدم لا مثيل لها. نحن
            نتمتع بثقة لأكثر من 5 سنوات، ونعتبر بوابتك إلى تحقيق أرباح رقمية
            معززة. انضم الآن واستمتع بتجربة تحقيق الدخل من عناوين URL بشكل لم
            يسبق له مثيل.
          </p>
        </div>
        <div className="container-services">
          {props.earningExperiences.map((earningExperience)=>(
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">{earningExperience.title}</h1>
              <p className="description">
                {earningExperience.description}
              </p>
            </div>
          </div>
          ))}
          {/* <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">الحد الأدنى للدفع منخفض </h1>
              <p className="description">
                إن عتبة الدفع المنخفضة لدينا تعني أنه بإمكانك صرف أموالك بمبلغ 2
                دولار فقط، مما يجعل رؤية أرباحك أسرع وأسهل.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">دعم الاستجابة السريعة </h1>
              <p className="description">
                استمتع بالمساعدة السريعة من فريق الإدارة المخصص لدينا والمجتمع
                النابض بالحياة.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">مكافأة الولاء </h1>
              <p className="description">
                ابق معنا وافتح المكافآت الإضافية المصممة خصيصًا لمستخدمينا
                المخلصين.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">تجاوز الروابط بسلاسة </h1>
              <p className="description">
                يضمن نظام تجربة المستخدم المحسّن لدينا أن يتمكن المستخدمون من
                تخطي الروابط دون أي عوائق، مما يعزز تجربة المستخدم.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">قل وداعا لـ Captchas
              </h1>
              <p className="description">
              استوفِ شروطًا معينة واستمتع برفاهية تعطيل رموز التحقق للحصول على تدفق أكثر سلاسة.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">قل وداعا لـ Captchas  </h1>
              <p className="description">
              استوفِ شروطًا معينة واستمتع برفاهية تعطيل رموز التحقق للحصول على تدفق أكثر سلاسة.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">الدفع السريع  </h1>
              <p className="description">
              استمتع بعملية صرف سريعة، حيث تستغرق من 1 إلى 7 أيام فقط حتى تتلقى أرباحك.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title"> السلامة هي أولويتنا </h1>
              <p className="description">
              نحن ملتزمون بضمان أقصى درجات الأمان وحماية بياناتك الثمينة في جميع الأوقات.
              </p>
            </div>
          </div>
          <div className="container-service">
            <img src={image} alt="" className="image-service" />
            <div className="container-text">
              <h1 className="title">لوحة معلومات شاملة  </h1>
              <p className="description">
              انغمس في لوحة المعلومات التفصيلية لدينا، والتي تتميز بأنظمة التحقق من صحة العرض الشفافة والصادقة.
              </p>
            </div>
          </div> */}
         
      
        </div>
      </div>
    </>
  );
}
