import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import "./SignIn.scss";
import imageLogo from '../../assets/download.png';
import imageGoogle from '../../assets/google.png';
import { useNavigate } from "react-router-dom";
import { User } from "../../Context/AuthContext/AuthContext";

export default function SignIn() {
  const navigate = useNavigate(); // For navigation
  const user = useContext(User)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post("https://bor-admin.elwakeil.com/public/api/register", {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.confirmPassword
      });
     const token = response.data.data.token
     const userDetails = response.data.data.user
     user.setAuth({token , userDetails})
      // Show success alert
      console.log(response.data.data);
      
      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "You have been successfully registered!",
        confirmButtonText: "Go to Dashboard",
      }).then(() => {
        navigate("/admin"); // Navigate to dashboard on confirmation
      });
    } catch (err) {
      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: err.response?.data?.message || "An error occurred. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form id="main-sign" onSubmit={handleSubmit}>
        <img src={imageLogo} alt="" className="image-logo" />
        <h1 className="heading">Welcome to Paidwork</h1>
        <p className="description">Start earning online</p>

        <input
          type="text"
          name="name"
          placeholder="Your Name"
          className="inputs"
          value={formData.name}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="inputs"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Enter Your Password"
          className="inputs"
          value={formData.password}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder="Password Confirmation"
          className="inputs"
          value={formData.confirmPassword}
          onChange={handleInputChange}
        />
        <button className="style-button" type="submit" disabled={loading}>
          {loading ? "Loading..." : "Continue"}
        </button>
        <p>OR</p>
        <div className="container-other-way">
          <img src={imageGoogle} alt="" className="images" />
          <p className="title">google</p>
        </div>
      </form>
    </>
  );
}
