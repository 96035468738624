import { createContext, useState } from "react";

// Rename this to align with the import expectations
export const User = createContext(null);

export default function UserProvider({ children }) {
  const [auth, setAuth] = useState({});
  return (
    <User.Provider value={{ auth, setAuth }}>{children}</User.Provider>
  );
}
