import React, { useEffect, useState } from "react";
import "./ProfitDashboard.scss";
import image from "../../../../assets/play-games.webp";
import axios from "axios";

export default function ProfitDashboard() {
  // State to control popup visibility
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle the popup
  const togglePopup = () => setIsPopupVisible(!isPopupVisible);

//   const fetchDataLinks = async () => {
//     try {
//       const response = await axios.get("https://bor-admin.elwakeil.com/public/api/links");
//       console.log(response.data.data);
      
//     } catch (error) {
//       console.error(error);
//     }
//   };
//  useEffect(() => {
//     fetchDataLinks();
//   }, []);
  return (
    <div id="profit-dashboard">
      <div className="container-profit">
        <img src={image} alt="" className="image-card" />
        <h1 className="title"> تصفح الروابط </h1>
        <button className="play-button" onClick={togglePopup}>
          يكسب
        </button>
      </div>
      <div className="container-profit">
        <img src={image} alt="" className="image-card" />
        <h1 className="title"> العب العاب </h1>
        <button className="play-button" onClick={togglePopup}>
          يكسب
        </button>
      </div>

      {/* Popup Component */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>الروابط المختصرة</h2>
            <table class="table table-striped">
            <thead>
    <tr>
      <th scope="col">الربط</th>
      <th scope="col">الحالة</th>
    </tr>
  </thead>
  <tbody>
    
    <tr>
      <td></td>
      <td>ahm*****</td>
    </tr>

  </tbody>
            </table>
            <button className="close-button" onClick={togglePopup}>
              إغلاق
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
