import "./Header.scss"
import logo from "../../assets/logo.png"
import { Link, NavLink } from "react-router-dom"
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";

export default function Header(){
  const [menu , setmenu] = useState(false);
const openMenu = () => {
  setmenu(!menu)
}
return(
    <>
    <div id="main-header" className="container">
    <NavLink
  to="/admin"
  className={({ isActive }) => (isActive ? "links-pages-header active" : "links-pages-header")}
>
  go to dashboard
</NavLink>    

    <img src={logo} alt="logo borogono site" className="logo-header" />
        <div className={`container-links ${menu ? "open" : ""}`}>
          <FaRegWindowClose className="icon-close"  onClick={openMenu}/>
        <NavLink
        onClick={openMenu}
  to="/"
  className={({ isActive }) => (isActive ? "links-pages-header active" : "links-pages-header")}
>
  الرئيسية
</NavLink>
<NavLink
onClick={openMenu}
  to="/proofpayment"
  className={({ isActive }) => (isActive ? "links-pages-header active" : "links-pages-header")}
>
  معدلات الدفع
</NavLink>
<NavLink
onClick={openMenu}
  to="/payoutrates"
  className={({ isActive }) => (isActive ? "links-pages-header active" : "links-pages-header")}
>
  اثباتات الدفع
</NavLink>

        </div>
<div className="container-btns-header">
<Link to={'/signin'}>
    
<button className="btn btn-light">تسجيل</button>
</Link>
    <Link to={'/login'}>
    
    <button className="btn btn-primary">دخول</button>
    </Link>
</div>
<IoMenu className="icon-menu" onClick={openMenu}/>
    </div>
    </>
)
}